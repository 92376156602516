<template>
    <FormComponent class="register">
      <h3 class="auth-title">
        {{ $t('auth.email_verification') }}
      </h3>
      <p>{{ $t('auth.email_verification_text') }}</p>
      <div class="checkbox">
        <router-link class="yup-purple-color" to="/login">
          <p>{{ $t('auth.user_check') }}</p>
        </router-link>
      </div>
    </FormComponent>
  </template>

<script>
// @ is an alias to /src
import DarkMode from '@/assets/mixins/detectDark';
import FormComponent from '../../components/form/FormComponent.vue';

export default {
  name: 'SuccessfullRegistration',
  beforeRouteEnter(to, from, next) {
    const registrationSuccess = localStorage.getItem('registrationSuccess');
    if (registrationSuccess) {
      localStorage.removeItem('registrationSuccess');
      localStorage.setItem('emailVerify', true);
      next();
    } else {
      next('/login'); // Redirecione para a página de cadastro
    }
  },
  components: {
    FormComponent,
  },
  mixins: [DarkMode],
  data() {
    return {
      show: false,
    };
  },
  computed: {
    isMainWhitelabel() {
      return (
        this.$store.state?.admin?.whitelabel?.['is_main'] ||
        !this.$store.state?.whitelabel
      );
    },
  },
  methods: {
    back() {
      if (this.$router.currentRoute.path === '/login') {
        this.$parent.routing = false;
      } else {
        this.$router.back();
      }
    },
  },
};
</script>

  <style lang="scss" scoped>
  .social-group {
    gap: 16px;
  }
  .auth-title {
    font-size: 36px;
    margin-bottom: 26px;
    .btn {
      padding: 0;
      font-size: 24px;
    }
  }
  .checkbox {
    margin-bottom: 26px;
    font-weight: 500;
  }
  </style>
